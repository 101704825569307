// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/tmp/7baf8779/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-template-blog-post-js": () => import("/tmp/7baf8779/src/templates/template-blog-post.js" /* webpackChunkName: "component---src-templates-template-blog-post-js" */),
  "component---src-templates-template-tag-page-js": () => import("/tmp/7baf8779/src/templates/template-tag-page.js" /* webpackChunkName: "component---src-templates-template-tag-page-js" */),
  "component---src-pages-404-js": () => import("/tmp/7baf8779/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apps-eye-twenty-index-js": () => import("/tmp/7baf8779/src/pages/apps/eye-twenty/index.js" /* webpackChunkName: "component---src-pages-apps-eye-twenty-index-js" */),
  "component---src-pages-apps-index-js": () => import("/tmp/7baf8779/src/pages/apps/index.js" /* webpackChunkName: "component---src-pages-apps-index-js" */),
  "component---src-pages-apps-russian-guru-index-js": () => import("/tmp/7baf8779/src/pages/apps/russian-guru/index.js" /* webpackChunkName: "component---src-pages-apps-russian-guru-index-js" */),
  "component---src-pages-blog-index-js": () => import("/tmp/7baf8779/src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-tags-js": () => import("/tmp/7baf8779/src/pages/blog/tags.js" /* webpackChunkName: "component---src-pages-blog-tags-js" */),
  "component---src-pages-index-js": () => import("/tmp/7baf8779/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/tmp/7baf8779/.cache/data.json")

